<template>
  <v-tooltip :text="translate(label)" :location="location">
    <template v-slot:activator="{ props }">
      <v-btn
        variant="elevated"
        :href="destination"
        :icon="icon"
        v-bind="props"
      />
    </template>
  </v-tooltip>
</template>
<script lang="ts" setup>
import translateRaw from "@/services/translate";
import { usePageContext } from "vike-vue/usePageContext";
const translate = translateRaw(usePageContext());
</script>
<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  data() {
    return {};
  },
  props: ["label", "destination", "icon", "location"],
});
</script>
