import { Translatable } from "@/types";
import { PageContext } from "vike/types";
export default function (context: PageContext) {
  return (item: Translatable) => {
    if (!item) return "translate test";
    if (typeof item == "string") item = context.translations[item] || item;
    if (typeof item == "string") return item;
    const language =
      globalThis.window?.navigator.language.split("-")[0] ?? "en";
    return item[language] || item.en;
  };
}
