<template>
  <v-toolbar>
    <TooltipIcon
      label="home"
      destination="/"
      :icon="mdiHome"
      location="bottom"
    />
    <v-toolbar-title><Translation item="title" /></v-toolbar-title>
    <TooltipIcon
      label="contact"
      destination="/contact"
      :icon="mdiHumanGreetingProximity"
      location="bottom"
    />
  </v-toolbar>
</template>
<script lang="ts">
import {
  mdiHome,
  mdiMagnify,
  mdiTimeline,
  mdiHumanGreetingProximity,
} from "@mdi/js";
import { defineComponent } from "vue";
export default defineComponent({
  data() {
    return {
      mdiHome,
      mdiMagnify,
      mdiTimeline,
      mdiHumanGreetingProximity,
    };
  },
});
</script>
