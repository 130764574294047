<template>
  {{ translated }}
</template>
<script lang="ts">
import translate from "@/services/translate";
import { defineComponent } from "vue";
import { usePageContext } from "vike-vue/usePageContext";
export default defineComponent({
  props: ["item"],
  computed: {
    translated(): string {
      return translate(usePageContext())(this.item);
    },
  },
});
</script>
