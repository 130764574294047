<template>
  <v-footer app>
    <v-spacer />
    <TooltipIcon
      label="source"
      destination="https://gitlab.rubend.nl/internal/rubend"
      :icon="mdiGitlab"
      location="top"
    />
    <v-icon :icon="mdiCopyright" />
    {{ new Date().getFullYear() }} —
    <a href="/contact" style="text-decoration: none; color: inherit">
      <strong><Translation item="name" /></strong>
    </a>
    <v-spacer />
  </v-footer>
</template>
<script lang="ts">
import { mdiGitlab, mdiCopyright } from "@mdi/js";
import { defineComponent } from "vue";
export default defineComponent({
  data() {
    return {
      mdiGitlab,
      mdiCopyright,
    };
  },
});
</script>
