// https://vike.dev/onCreateApp
import { registerPlugins } from "@/plugins";

export { onCreateApp };

import type { OnCreateAppSync } from "vike-vue/types";

const onCreateApp: OnCreateAppSync = (
  pageContext,
): ReturnType<OnCreateAppSync> => {
  const { app } = pageContext;
  registerPlugins(app);
};
